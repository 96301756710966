/**
 * Styles
 */
import { applyMiddleware, createStore } from 'redux';
import reducers from './reducers';
import './style.scss';
import { here_api, internal_api } from './utils/axios';

/**
 * External Dependencies
 */
const jQuery = require('jquery');
const createStoreWithMiddleware = applyMiddleware()(createStore);

window.jQuery = jQuery;
window.$ = jQuery;
window.api = internal_api;
window.here = here_api;
window.store = createStoreWithMiddleware(reducers)

/*------------------------------------------------------------------

  RootUI Class

-------------------------------------------------------------------*/
class ROOTUI { }
window.RootUI = new ROOTUI();