/**
 * External Dependencies
 */
import Cookies from 'js-cookie';
import Omit from 'object.omit';
import { combineReducers } from 'redux';

/**
 * Internal Dependencies
 */
import {
    ADD_TOAST,
    REMOVE_TOAST, UPDATE_AUTH,
    UPDATE_SETTINGS
} from '../actions';
import defaultSettings from '../settings';
import { getUID } from '../utils';

// initial state.
const INITIAL_SETTINGS_STATE = {
    ...defaultSettings,
};
const INITIAL_AUTH_STATE = {
    token: Cookies.get('shell-auth-login'),
};
const INITIAL_TOASTS_STATE = [];

/**
 * Reducer
 */
const rootReducer = combineReducers({
    auth: (state = INITIAL_AUTH_STATE, action) => {
        switch (action.type) {
            case UPDATE_AUTH:
                // save token to cookies for 3 days.
                if (typeof action.auth.token !== 'undefined') {
                    Cookies.set('shell-auth-login', action.auth.token, { expires: 28, sameSite: 'strict', secure: true });
                }

                return Object.assign({}, state, action.auth);
            case "DEFAULT":
            default:
                return state;
        }
    },
    settings: (state = INITIAL_SETTINGS_STATE, action) => {
        switch (action.type) {
            case UPDATE_SETTINGS:
                return Object.assign({}, state, action.settings);
            case "DEFAULT":
            default:
                return state;
        }
    },
    toasts: (state = INITIAL_TOASTS_STATE, action) => {
        switch (action.type) {
            case ADD_TOAST:
                const newData = {
                    ...{
                        title: '',
                        content: '',
                        color: 'brand',
                        time: false,
                        duration: 0,
                        closeButton: true,
                    },
                    ...action.data,
                };

                if (newData.time === true) {
                    newData.time = new Date();
                }

                return {
                    ...state,
                    [getUID()]: newData,
                };
            case REMOVE_TOAST:
                if (!action.id || !state[action.id]) {
                    return state;
                }
                return Omit(state, action.id);
            default:
                return state;
        }
    },
});

export default rootReducer;
