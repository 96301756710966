import moment from 'moment';
import Swal from 'sweetalert2';

export const canUseDOM = !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
);

/**
 * Check if email is valid.
 *
 * @param {string} email email string.
 *
 * @return {boolean} is valid.
 */
export function isValidEmail(email) {
    // NOSONAR
    const reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(email);
}

export async function isValidCF(cf) {
    // NOSONAR
    const reg = /^[a-zA-Z]{6}\d{2}[abcdehlmprstABCDEHLMPRST]{1}\d{2}([a-zA-Z]{1}\d{3})[a-zA-Z]{1}$/;
    return reg.test(cf);
}

export async function removeDuplicates(data) {
    return data.filter((value, index) => data.indexOf(value) === index);
}

export function deleteByValue(val, object) {
    for (let f in object) {
        if (object[f] == val) {
            delete object[f];
        }
    }
    return object;
}

export function getDayName(dateStr, locale) {
    let date = new Date(dateStr);
    return date.toLocaleDateString(locale, { weekday: 'long' });
}

export function getMonthName(dateStr, locale) {
    let date = new Date(dateStr);
    return date.toLocaleDateString(locale, { month: 'long' });
}

/**
 * Get unique ID.
 *
 * @return {string} uid.
 */
export function getUID() {
    const typedArray = new Uint8Array(1);
    const randomValue = crypto.getRandomValues(typedArray)[0];
    const randomFloat = randomValue / Math.pow(2, 8);
    return (
        Number(String(randomFloat).slice(2)) +
        Date.now() +
        Math.round(window.performance.now())
    ).toString(36);
}

/**
 * Convert file size to human readable string.
 *
 * @param {number} size file size.
 *
 * @return {string} human readable size.
 */
export function fileSizeToHumanReadable(size) {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return ((size / Math.pow(1024, i)).toFixed(2) * 1) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

export function getFirstDayOfMonth(month) {
    let date = new Date();
    let FirstDay = moment(new Date((date.getFullYear()).toString(), month,)).format("YYYY-MM-DD");

    return FirstDay;
}

export function getLastDayOfMonth(month) {

    let date = new Date();
    let LastDay = moment(new Date((date.getFullYear()).toString(), month + 1, 0)).format("YYYY-MM-DD");

    return LastDay;
}

function titleCase(str) {
    let splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
}

function alertMissing(key) {
    let field = key.replace("_", " ");
    Swal.fire(
        'Oops!',
        'Campo ' + titleCase(field) + ' mancante o incompleto.',
        'error'
    )
}

export async function serializeData(data, optional_fields) {
    let body = {};
    for (let [key, value] of data.entries()) {
        if (optional_fields.indexOf(key) < 0) {
            if (value == undefined || value == "" || value == "undefined") {
                alertMissing(key);
                return false;
            }
        }
        body[`${key}`] = value;
    }
    return body;
}

export function showError(err) {
    let errore = "Oops!";
    let erroreStr = "Ricontrolla l'operazione effettuata";
    let icon = "error";
    if (err.response) {
        if (err.response.status == 422) {
            errore = "Uno o più parametri mancanti"
            erroreStr = err.response.data?.stato || erroreStr;
            icon = "warning";
        } else {
            errore = err.response.data.error;
            if (err.response.data.dettaglio) {
                erroreStr = err.response.data.dettaglio;
            }
        }
    } else {
        erroreStr = err;
    }
    Swal.fire({
        title: errore,
        text: erroreStr,
        icon: icon
    });
}

export function isStringified(str) {
    try {
        return JSON.parse(str);
    } catch (err) {
        return false;
    }
}

export function selectToArray(select) {

    if (select != undefined) {
        let final_select = [];
        for (let s of select) {
            final_select.push(s.value);
        }
        return JSON.stringify(final_select);
    }
    return '';
}

export function valueToSelect(value, select) {
    let selectedElement;
    if (select != undefined) {
        selectedElement = (select.find(el => el.value == value))
    }
    return selectedElement;
}

export async function caricaFeedRss(url) {
    return new Promise((resolve) => {
        let body = {
            url: url
        }
        window.api.rss.getFeed(body)
            .then((res) => {
                resolve(res.data.feed);
            })
            .catch((err) => {
                showError(err);
            })
    })
}