/**
 * External Dependencies
 */
import Avvvatars from "avvvatars-react";
import classnames from "classnames/dedupe";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
/**
 * Internal Dependencies
 */
import { initNavbar } from "../../../../common-assets/js/rootui-parts/initNavbar";
import {
  updateAuth as actionUpdateAuth,
  updateSettings as actionUpdateSettings,
} from "../../actions";
import Dropdown from "../bs-dropdown";
import Icon from "../icon";

const $ = window.jQuery;

window.RootUI.initNavbar = initNavbar;

/**
 * Component
 */
class PageNavbar extends Component {
  constructor(props) {
    super(props);

    window.RootUI.initNavbar();

    this.state = {
      mobileMenuShow: false,
      banner1Css: { color: "#FFF", backgroundColor: "green" },
      banner2Css: {
        color: "#000",
        backgroundColor: "grey",
        fontFamily: "arial",
      },
      banner3Css: { color: "#FFF", backgroundColor: "#EB7C10", fontSize: 14 },
    };

    this.logOut = this.logOut.bind(this);
    this.renderSubmenus = this.renderSubmenus.bind(this);
    this.renderRightMenuItems = this.renderRightMenuItems.bind(this);
  }

  componentDidMount() {
    $(document).on("keydown.rui-navbar", (e) => {
      const { mobileMenuShow } = this.state;

      if (mobileMenuShow && e.keyCode === 27) {
        this.setState({
          mobileMenuShow: false,
        });
      }
    });
    this.loadProfilePic();
    this.loadUsername();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.settings.update_pic != this.props.settings.update_pic) {
      this.loadProfilePic();
    }
  }

  componentWillUnmount() {
    $(document).off("keydown.rui-navbar");
  }

  logOut() {
    const { updateAuth } = this.props;

    window.api.auth
      .logout()
      .then((res) => {
        if (res.status == 201) {
          updateAuth({
            token: "",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  loadProfilePic() {
    window.api.profilo
      .avatar({ id_dipendente: undefined })
      .then((res) => {
        const profile_data = res.data;
        this.setState({
          profile_pic: profile_data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  loadUsername() {
    window.api.profilo
      .username()
      .then((res) => {
        this.setState({
          username: res.data.username,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  dropdownUser(profile_pic, settings) {
    return (
      <Dropdown tag="div" direction="up" showTriangle>
        <Dropdown.Toggle
          tag="a"
          href="#/profilo"
          className="dropdown-item rui-navbar-avatar"
        >
          <Avvvatars style="shape" size={40} value={profile_pic} />
        </Dropdown.Toggle>
        <Dropdown.Menu tag="ul" className="nav dropdown-menu">
          <li>
            <Link to="/profilo" className="nav-link">
              <Icon name="IconUserCircle" />
              <span>Profilo</span>
              <span className="rui-nav-circle" />
            </Link>
          </li>
          <li>
            <div className="custom-control custom-switch dropdown-item-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="toggleNightMode"
                checked={settings.night_mode}
                onChange={() => {
                  this.props.updateSettings({
                    night_mode: !settings.night_mode,
                  });
                  localStorage.setItem("night_mode", !settings.night_mode);
                }}
              />

              <label
                className="dropdown-item custom-control-label"
                htmlFor="toggleNightMode"
              >
                <Icon name="IconMoon" />
                &nbsp;
                <span>Night Mode</span>
                <span className="rui-dropdown-circle" />
              </label>
            </div>
          </li>
          <li>
            <Link to="#" className="nav-link" onClick={this.logOut}>
              <Icon name="IconLogout" />
              <span>Esci</span>
              <span className="rui-nav-circle" />
            </Link>
          </li>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  renderSubmenus(nav, isMobile, level = 1) {
    return Object.keys(nav).map((url) => {
      const data = nav[url];

      const isActive = window.location.hash === `#${url}`;

      const LinkContent = data.name ? (
        <>
          {data.icon ? (
            <>
              <Icon name={data.icon} />
              <span>{data.name}</span>
              <span
                className={data.sub ? "rui-dropdown-circle" : "rui-nav-circle"}
              />
            </>
          ) : (
            data.name
          )}
        </>
      ) : (
        ""
      );

      return (
        <React.Fragment key={`${url}-${data.name}`}>
          {data.sub ? (
            <Dropdown
              tag="li"
              className={classnames(isActive ? "active" : "")}
              direction={level === 1 ? "up" : "right"}
              openOnHover={!isMobile}
              showTriangle
            >
              <Dropdown.Toggle tag="a" href="#" className="dropdown-item">
                {LinkContent}
              </Dropdown.Toggle>
              <Dropdown.Menu tag="ul" className="nav dropdown-menu">
                {this.renderSubmenus(data.sub, isMobile, level + 1)}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <li className={classnames("nav-item", isActive ? "active" : "")}>
              <Link to={data.sub ? "#" : url} className="nav-link">
                {LinkContent}
              </Link>
            </li>
          )}
        </React.Fragment>
      );
    });
  }

  renderRightMenuItems(isMobile) {
    const { settings } = this.props;

    const { profile_pic, username } = this.state;

    return (
      <>
        {!isMobile ? <li className="nav-item">{username}</li> : <></>}
        {!isMobile ? this.dropdownUser(profile_pic, settings) : ""}
      </>
    );
  }

  render() {
    const { settings } = this.props;

    const { mobileMenuShow, profile_pic } = this.state;

    return (
      <>
        {/* Nav Menu */}
        <nav
          className={classnames(
            "rui-navbar rui-navbar-top",
            settings.nav_dark ? "rui-navbar-dark" : "",
            settings.nav_sticky ? "rui-navbar-sticky" : "",
            settings.nav_fixed ? "rui-navbar-fixed" : "",
            settings.nav_expand
              ? `rui-navbar-expand-${settings.nav_expand}`
              : ""
          )}
        >
          <div className="rui-navbar-brand">
            {settings.nav_logo_path ? (
              <Link to={settings.nav_logo_url} className="rui-navbar-logo">
                <img
                  src={
                    settings.night_mode || settings.nav_dark
                      ? settings.nav_logo_white_path
                      : settings.nav_logo_path
                  }
                  alt=""
                  width={settings.nav_logo_width}
                />
              </Link>
            ) : (
              ""
            )}

            <button className="yay-toggle rui-yaybar-toggle" type="button">
              <span />
            </button>
          </div>
          <div
            className={`container${
              settings.nav_container_fluid ? "-fluid" : ""
            }`}
          >
            <div className="rui-navbar-content">
              <ul className="nav"></ul>
              <ul className="nav rui-navbar-right">
                {this.renderRightMenuItems()}
              </ul>
            </div>
          </div>
        </nav>

        {/* Mobile Menu */}
        <nav
          className={classnames(
            "rui-navbar rui-navbar-mobile",
            settings.nav_dark ? "rui-navbar-dark" : "",
            settings.nav_expand
              ? `rui-navbar-expand-${settings.nav_expand}`
              : "",
            mobileMenuShow ? "rui-navbar-show" : ""
          )}
        >
          <div className="rui-navbar-head">
            {!mobileMenuShow ? (
              <button
                className="rui-yaybar-toggle rui-yaybar-toggle-inverse yay-toggle"
                type="button"
                aria-label="Toggle side navigation"
              >
                <span />
              </button>
            ) : (
              ""
            )}
            {settings.nav_logo_path ? (
              <Link
                to={settings.nav_logo_url}
                className="rui-navbar-logo mr-auto"
              >
                <img
                  src={
                    settings.night_mode || settings.nav_dark
                      ? settings.nav_logo_white_path
                      : settings.nav_logo_path
                  }
                  alt=""
                  width={settings.nav_logo_width}
                />
              </Link>
            ) : (
              ""
            )}
            {this.dropdownUser(profile_pic, settings)}
          </div>
        </nav>
        <div
          className="rui-navbar-bg"
          onClick={() => {
            this.setState({
              mobileMenuShow: !mobileMenuShow,
            });
          }}
          onKeyUp={() => {}}
          role="button"
          tabIndex={0}
        />
      </>
    );
  }
}

export default connect(
  ({ settings }) => ({
    settings,
  }),
  {
    updateAuth: actionUpdateAuth,
    updateSettings: actionUpdateSettings,
  }
)(PageNavbar);
