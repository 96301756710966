/**
 * Internal Dependencies
 */
// Auth
import QuattroCentoTre from "./403";
import AuthSignIn from "./AuthSignIn";

// Start
import Dashboard from "./Dashboard";
import Profilo from "./Profilo";

// Apps
import Auto from "./Auto";
import Clienti from "./Clienti";
import Dipendenti from "./Dipendenti";
import Fatturazione from "./Fatturazione";
import Ferie from "./Ferie";
import NotaSpese from "./NotaSpese";
import Offerte from "./Offerte";
import Planning from "./Planning";

// Admin
import GestioneClienti from "./GestioneClienti";
import GestioneCommesse from "./GestioneCommesse";
import GestioneFornitori from "./GestioneFornitori";
import GestioneOfferte from "./GestioneOfferte";
import GestioneSottocommesse from "./GestioneSottocommesse";

import Configurazioni from "./Configurazioni";
import GestioneDipendenti from "./GestioneDipendenti";
import GestioneDipendentiBuoniPasto from "./GestioneDipendenti/buoni-pasto";
import EstrazioniDipendenti from "./GestioneDipendenti/estrazioni";
import GestioneDipendentiCedolino from "./GestioneDipendenti/ferie-cedolino";
import GestioneUtentiRealtime from "./GestioneDipendenti/ferie-realtime";
import GestioneDipendentiOreRichieste from "./GestioneDipendenti/permessi-straordinari";
import GestioneDipendentiSmartWork from "./GestioneDipendenti/smartwork";
import GestionePlanning from "./GestionePlanning";
import Permessi from "./Permessi";
import RichiesteFerie from "./RichiesteFerie";
import RichiesteExtra from "./RichiesteFerie/extra";
import RichiesteSmartworking from "./RichiesteSmartworking";

import CostiBenzina from "./CostiBenzina";
import GestioneAuto from "./GestioneAuto";
import GestioneCostiTenantAWS from "./GestioneCostiTenantAWS";
import GestioneExpenseReportVoucher from "./GestioneExpenseReportVoucher";
import ReportPlanningContrattiSettimanali from "./Report/Planning/check-settimana";
import ReportPlanning from "./Report/Planning/estrazioni";
import RichiesteAuto from "./RichiesteAuto";

export default {
  "/sign-in": AuthSignIn,
  "/403": QuattroCentoTre,

  "/": Dashboard,
  "/profilo": Profilo,

  // Apps
  "/planning/:funzione?/:id?/:data?": Planning,
  "/clienti/:id?": Clienti,
  "/dipendenti/:id?": Dipendenti,
  "/auto": Auto,
  "/permessi": Ferie,
  "/nota-spese": NotaSpese,
  "/nota-spese/giustificativi": GestioneExpenseReportVoucher,
  "/fatturazione": Fatturazione,
  "/offerte": Offerte,

  "/estrazioni-planning": ReportPlanning,
  "/estrazioni-planning/check-contratti-settimanali":
    ReportPlanningContrattiSettimanali,
  "/estrazioni-dipendenti": EstrazioniDipendenti,
  "/estrazioni-dipendenti/utenti-cedolino": GestioneDipendentiCedolino,
  "/estrazioni-dipendenti/ferie-realtime": GestioneUtentiRealtime,
  "/estrazioni-dipendenti/buoni-pasto": GestioneDipendentiBuoniPasto,
  "/estrazioni-dipendenti/smartwork": GestioneDipendentiSmartWork,
  "/estrazioni-dipendenti/permessi-straordinari":
    GestioneDipendentiOreRichieste,
  // Admin
  "/gestione-clienti": GestioneClienti,
  "/gestione-fornitori": GestioneFornitori,
  "/aws-costi-tenant": GestioneCostiTenantAWS,
  "/gestione-offerte": GestioneOfferte,
  "/gestione-commesse": GestioneCommesse,
  "/gestione-sottocommesse": GestioneSottocommesse,

  "/gestione-dipendenti": GestioneDipendenti,

  /* '/richieste-nota-spese': GestioneNotaSpese, */
  "/richieste-permessi/:tab?": RichiesteFerie,
  "/richieste-extra/:tab?": RichiesteExtra,
  "/richieste-smartworking/:tab?": RichiesteSmartworking,
  "/gestione-planning/:tab?": GestionePlanning,

  "/gestione-auto": GestioneAuto,
  "/richieste-auto/:tab?": RichiesteAuto,
  "/tabella-costi": CostiBenzina,

  configurazioni: Configurazioni,
  "/autorizzazioni": Permessi,
};
