/**
 * Styles.
 */
import './style.scss';

/**
 * External Dependencies
 */
import React, { Component } from 'react';

/**
 * Internal Dependencies
 */
import PageTitle from '../../components/page-title';
import PageWrap from '../../components/page-wrap';

/**
 * Component
 */
class Configurazioni extends Component {
    render() {
        return (
            <PageWrap>
                <PageTitle
                    breadcrumbs={{
                        '/': 'Home',
                        '/gestione-dipendenti': {
                            title: 'Admin',
                            sub: 'admin',
                        },
                    }}
                >
                    <h1>Configurazioni</h1>
                </PageTitle>
            </PageWrap>
        );
    }
}

export default Configurazioni;
