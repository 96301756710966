import axios from "axios";
import dotenv from "dotenv";
import Swal from "sweetalert2";
dotenv.config();

const here = axios.create();
const api = axios.create();
api.defaults.baseURL =
  process.env.NODE_ENV == "development"
    ? process.env.REACT_APP_API_ENDPOINT
    : "https://next.sinetinformatica.cloud/api";
api.defaults.headers.post["accept"] = "application/json";
api.defaults.withCredentials = true;

let refreshTokenPromise;

const getRefreshToken = (_originalRequest) =>
  api.get("/auth/refreshtoken").then((res) => {
    if (res.status === 201) {
      return res;
    }
  });

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error == "Error: Network Error") {
      Swal.fire("Errore nel contattare il server, riprova più tardi.");
      return Promise.reject(error);
    }

    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      originalRequest.url === "/auth/refreshtoken"
    ) {
      await window.store.dispatch({
        type: "UPDATE_AUTH",
        auth: {
          token: "",
        },
      });
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!refreshTokenPromise) {
        // check for an existing in-progress request
        originalRequest._retry = true;
        // if nothing is in-progress, start a new refresh token request
        refreshTokenPromise = getRefreshToken(originalRequest).then(() => {
          refreshTokenPromise = null; // clear state
        });
      }

      return refreshTokenPromise.then((_token) => {
        window.store.dispatch({
          type: "UPDATE_AUTH",
          auth: {
            token: true,
          },
        });
        return api(originalRequest);
      });
    }

    return Promise.reject(error);
  }
);

export const here_api = {
  autocomplete: (indirizzo) => {
    return here.get(
      "https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?apiKey=B5RRqb-l1eeK-IXld2uJ3a-h6RhbASAX4TYEhOT70Dc&query=" +
        indirizzo
    );
  },
};

export const internal_api = {
  error: {
    log: (body) => {
      return api.post("/error/log", body);
    },
  },
  chat: {
    response: (message) => {
      return api.get("/chat/response?message=" + message);
    },
  },
  regioni: {
    lista: () => {
      return api.get("/regioni/lista");
    },
  },
  auth: {
    id: () => {
      return api.get("/auth/id");
    },
    elenco: () => {
      return api.get("/auth/elenco");
    },
    signup: (body) => {
      return api.post("/auth/signup", body);
    },
    isadmin: (risorsa) => {
      return api.get("/auth/isadmin?risorsa=" + risorsa);
    },
    refreshtoken: () => {
      return api.get("/auth/refreshtoken");
    },
    permessi: () => {
      return api.get("/auth/permessi");
    },
    loggedIn: () => {
      return api.get("/auth/loggedin");
    },
    logout: () => {
      return api.post("/auth/logout");
    },
    abilita: (body) => {
      return api.post("/auth/abilita", body);
    },
    disabilita: (body) => {
      return api.post("/auth/disabilita", body);
    },
    getPolicy: () => {
      return api.get("/auth/policy");
    },
    enablePolicy: () => {
      return api.post("/auth/policy");
    },
    elimina: (body) => {
      return api.post("/auth/elimina", body);
    },
  },
  planning: {
    eventi: (params) => {
      return api.get("/planning/eventi?" + $.param(params));
    },
    evento: (params) => {
      return api.get("/planning/evento?" + $.param(params));
    },
    history: (id_evento) => {
      return api.get("/planning/history?id_evento=" + id_evento);
    },
    oggi: () => {
      return api.get("/planning/oggi");
    },
    domani: () => {
      return api.get("/planning/domani");
    },
    dati: (id_evento) => {
      return api.get("/planning/evento?id_evento=" + id_evento);
    },
    aggiungi: (body) => {
      return api.post("/planning/aggiungi", body);
    },
    previewDashboardDipendente: () => {
      return api.get("/planning/modifiche");
    },
    previewDashboardAdmin: () => {
      return api.get("/planning/modifiche-admin");
    },
    modifica: (body) => {
      return api.post("/planning/modifica", body);
    },
    smartworkRequest: (body) => {
      return api.post("/planning/smartworking-request/inserisci", body);
    },
    smartworkRequestManage: (body) => {
      return api.post("/planning/smartworking-request/gestisci", body);
    },
    smartworkRequestList: (body) => {
      return api.post("/planning/smartworking-request/lista", body);
    },
    indirizzi: () => {
      return api.get("/planning/indirizzi");
    },
    elimina: (body) => {
      return api.post("/planning/elimina", body);
    },
    valida: (data) => {
      return api.post("/planning/valida", data);
    },
    buonipasto: (ute, data) => {
      return api.get("/buonipasto/conteggio/" + ute + "?data=" + data);
    },
    smartworkCounter: (ute, data) => {
      return api.get(
        "/planning/conteggio-smartworking/" + ute + "?data=" + data
      );
    },
    permessiStraordinariCounter: (ute, data) => {
      return api.get(
        "/planning/conteggio-richieste-ore/" + ute + "?data=" + data
      );
    },
  },
  gruppi: {
    elenco: () => {
      return api.get("/gruppi/elenco");
    },
    elencoUtente: (utente) => {
      return api.get("/gruppi/elenco-utente/" + utente);
    },
    assegna: (body) => {
      return api.post("/gruppi/assegna", body);
    },
    aggiungi: (body) => {
      return api.post("/gruppi/aggiungi", body);
    },
    modifica: (body) => {
      return api.post("/gruppi/modifica", body);
    },
    elimina: (body) => {
      return api.post("/gruppi/elimina", body);
    },
  },
  risorse: {
    elenco: (id_gruppo = undefined) => {
      let gruppo = id_gruppo != undefined ? "?id_gruppo=" + id_gruppo : "";
      return api.get("/risorse/elenco" + gruppo);
    },
    assegna: (body) => {
      return api.post("/risorse/assegna", body);
    },
    autorizzato: (url) => {
      return api.get("/risorse/autorizzato?url=" + url);
    },
  },
  aree: {
    elenco: () => {
      return api.get("/aree/elenco");
    },
  },
  expenseReportVoucher: {
    elenco: () => {
      return api.get("/expenseReportVoucher");
    },
    export: () => {
      return api.get("/expenseReportVoucher");
    },
    tipologie: () => {
      return api.get("/expenseReportVoucher/selectTipologie");
    },
    dati: (id) => {
      return api.get(`/expenseReportVoucher/${id}`);
    },
    registra: (dati) => {
      return api.post("/expenseReportVoucher", dati);
    },
    modifica: (id, dati) => {
      return api.put(`/expenseReportVoucher/${id}`, dati);
    },
    abilita: (id, newStato) => {
      return api.patch(`/expenseReportVoucher/${id}`, {
        activate: newStato ? 1 : 0,
      });
    },
    elimina: (id) => {
      return api.delete(`/expenseReportVoucher/${id}`);
    },
  },
  clienti: {
    elencoSedi: () => {
      return api.get("/clienti/sedi/elenco");
    },
    export: () => {
      return api.get("/clienti/export");
    },
    elenco: () => {
      return api.get("/clienti/elenco");
    },
    elencoContrattiAttivi: () => {
      return api.get("/clienti/elenco-contratti-attivi");
    },
    eventi: (params) => {
      return api.get("/clienti/eventi?" + $.param(params));
    },
    registra: (body) => {
      return api.post("/clienti/registra", body);
    },
    modifica: (body) => {
      return api.post("/clienti/modifica", body);
    },
    dati: (id_cliente) => {
      return api.get("/clienti/dati?id_cliente=" + id_cliente);
    },
    preview: () => {
      return api.get("/clienti/preview");
    },
    previewCard: (id_cliente) => {
      let cliente = id_cliente != undefined ? "?id_cliente=" + id_cliente : "";
      return api.get("/clienti/preview-card" + cliente);
    },
    numeroCard: () => {
      return api.get("/clienti/numero-card");
    },
    abilita: (body) => {
      return api.post("/clienti/abilita", body);
    },
    disabilita: (body) => {
      return api.post("/clienti/disabilita", body);
    },
    elimina: (body) => {
      return api.post("/clienti/elimina", body);
    },
    dipendenti: (id_cliente) => {
      return api.get("/clienti/dipendenti?id_cliente=" + id_cliente);
    },
    indirizzi: () => {
      return api.get("/clienti/indirizzi");
    },
    selectIndirizzo: (id_cliente) => {
      return api.get("/clienti/indirizzo?id_cliente=" + id_cliente);
    },
  },
  anagrafica: {
    elencoRuoli: () => {
      return api.get("/anagrafica/ruoli/elenco");
    },
    elencoNumeri: () => {
      return api.get("/anagrafica/numeri/elenco");
    },
    elencoTelegram: () => {
      return api.get("/anagrafica/telegram/elenco");
    },
    export: () => {
      return api.get("/anagrafica/export");
    },
    elencoCard: (params) => {
      return api.get("/anagrafica/elenco-card?" + $.param(params));
    },
    preview: () => {
      return api.get("/anagrafica/preview");
    },
    dati: (id_utente) => {
      return api.get("/anagrafica/dati?id_utente=" + id_utente);
    },
    modifica: (body) => {
      return api.post("/anagrafica/modifica", body);
    },
    compleanni: (body) => {
      return api.get("/anagrafica/compleanni", body);
    },
    numeroCard: () => {
      return api.get("/anagrafica/numero-card");
    },
    richiesteTipologie: () => {
      return api.get("/anagrafica/richieste-tipologie");
    },
    datiNotaSpese: (params) => {
      return api.get("/anagrafica/dati-notaspese?" + $.param(params));
    },
  },
  info: {
    manutenzione: () => {
      return api.get("/info/manutenzione");
    },
  },
  contratti: {
    elenco: (id_cliente) => {
      return api.get("/contratti/elenco?id_cliente=" + id_cliente);
    },
    costoTenantAWS: (idContratto) => {
      let converted = "";
      if (idContratto) {
        converted = Buffer.from(idContratto).toString("base64");
      }
      return api.get("/contratti/costiTenant/" + converted);
    },
    checkVincoloSettimanaleContratto: (data) => {
      return api.get(
        "/contratti/checkVincoloSettimanaleContratto?data=" + data
      );
    },
    export: () => {
      return api.get("/contratti/export");
    },
    previewDashboardDipendente: () => {
      return api.get("/contratti/nuovi");
    },
    numeroCard: () => {
      return api.get("/contratti/numero-card");
    },
    preview: () => {
      return api.get("/contratti/preview");
    },
    dati: (id) => {
      return api.get("/contratti/dati?id_commessa=" + id);
    },
    registra: (body) => {
      return api.post("/contratti/registra", body);
    },
    modifica: (body) => {
      return api.post("/contratti/modifica", body);
    },
    abilita: (body) => {
      return api.post("/contratti/abilita", body);
    },
    disabilita: (body) => {
      return api.post("/contratti/disabilita", body);
    },
  },
  sottocontratti: {
    preview: () => {
      return api.get("/sottocontratti/preview");
    },
    export: () => {
      return api.get("/sottocontratti/export");
    },
    dati: (id) => {
      return api.get("/sottocontratti/dati?id_sottocommessa=" + id);
    },
    elenco: (id_contratto) => {
      return api.get("/sottocontratti/elenco?id_contratto=" + id_contratto);
    },
    registra: (body) => {
      return api.post("/sottocontratti/registra", body);
    },
    modifica: (body) => {
      return api.post("/sottocontratti/modifica", body);
    },
    abilita: (body) => {
      return api.post("/sottocontratti/abilita", body);
    },
    disabilita: (body) => {
      return api.post("/sottocontratti/disabilita", body);
    },
  },
  profilo: {
    avatar: (params) => {
      return api.get("/profilo/avatar?" + $.param(params));
    },
    username: () => {
      return api.get("/profilo/username");
    },
    dati: (params) => {
      return api.get("/profilo/dati?" + $.param(params));
    },
    aggiorna: (body) => {
      return api.post("/profilo/aggiorna", body);
    },
  },
  auto: {
    elenco: () => {
      return api.get("/auto/elenco");
    },
    registra: (body) => {
      return api.post("/auto/registra", body);
    },
    modifica: (body) => {
      return api.post("/auto/modifica", body);
    },
    accetta: (body) => {
      return api.post("/auto/richieste/accetta", body);
    },
    rifiuta: (body) => {
      return api.post("/auto/richieste/rifiuta", body);
    },
    assegna: (body) => {
      return api.post("/auto/richieste/assegna", body);
    },
    updateCostiPeriodo: (body) => {
      return api.post("/auto/costo-periodo/aggiorna", body);
    },
    updateCostiDipendente: (body) => {
      return api.post("/auto/costo-dipendente/aggiorna", body);
    },
    costiPeriodo: (anno) => {
      return api.get("/auto/costo-periodo?anno=" + anno);
    },
    costiDipendente: () => {
      return api.get("/auto/costo-dipendenti");
    },
    calendario: (mese) => {
      return api.get("/auto/calendario?mese=" + mese);
    },
    dati: (id_auto) => {
      return api.get("/auto/dati?id_auto=" + id_auto);
    },
    richiesta: (body) => {
      return api.post("/auto/richieste/inserisci", body);
    },
    previewRichieste: (tipo) => {
      return api.get("/auto/richieste/preview?tipo=" + tipo);
    },
    previewDashboardDipendente: () => {
      return api.get("/auto/richieste/dashboard/dipendente");
    },
    previewDashboardAdmin: () => {
      return api.get("/auto/richieste/dashboard/admin");
    },
    eliminaRichiesta: (body) => {
      return api.post("/auto/richieste/elimina", body);
    },
    numeroCard: () => {
      return api.get("/auto/numero-card");
    },
    preview: () => {
      return api.get("/auto/preview");
    },
    abilita: (body) => {
      return api.post("/auto/abilita", body);
    },
    disabilita: (body) => {
      return api.post("/auto/disabilita", body);
    },
    elimina: (body) => {
      return api.post("/auto/elimina", body);
    },
  },
  fatturazione: {
    estrai: (params) => {
      return api.get("/fatturazione/estrai?" + $.param(params));
    },
    fattura: (body) => {
      return api.post("/fatturazione/fattura", body);
    },
  },
  notaspese: {
    calcola: (params) => {
      return api.get("/notaspese/calcola?" + $.param(params));
    },
    invia: (body) => {
      return api.post("/notaspese/invia", body);
    },
  },
  offerte: {
    elenco: () => {
      return api.get("/offerte/elenco");
    },
    export: () => {
      return api.get("/offerte/export");
    },
    previewRichieste: (tipo) => {
      return api.get("/offerte/richieste/preview?tipo=" + tipo);
    },
    preview: () => {
      return api.get("/offerte/preview");
    },
    dati: (id_offerta) => {
      return api.get("/offerte/dati?id_offerta=" + id_offerta);
    },
    registra: (body) => {
      return api.post("/offerte/registra", body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    history: (id_offerta) => {
      return api.get("/offerte/richieste/history?id_offerta=" + id_offerta);
    },
    elimina: (body) => {
      return api.post("/offerte/elimina", body);
    },
    modifica: (body) => {
      return api.post("/offerte/modifica", body);
    },
    schedaTempi: (id_offerta, headers) => {
      return api.get("/offerte/scheda-tempi?id_offerta=" + id_offerta, headers);
    },
    accetta: (body) => {
      return api.post("/offerte/richieste/accetta", body);
    },
    rifiuta: (body) => {
      return api.post("/offerte/richieste/rifiuta", body);
    },
  },
  ferie: {
    conteggio: (utente = undefined) => {
      return api.get(
        "/ferie/conteggio" + (utente !== undefined ? "/" + utente : "")
      );
    },
    cedolino: (utente = undefined) => {
      return api.get(
        "/ferie/cedolino" + (utente !== undefined ? "/" + utente : "")
      );
    },
    richiesta: (body) => {
      return api.post("/ferie/richieste/inserisci", body);
    },
    richiestaExtra: (body) => {
      return api.post("/ferie/richieste/inserisciExtra", body);
    },
    dati: (id_richiesta) => {
      return api.get("/ferie/richieste/dati/" + id_richiesta);
    },
    assegna: (body) => {
      return api.post("/ferie/richieste/assegna", body);
    },
    accetta: (body) => {
      return api.post("/ferie/richieste/accetta", body);
    },
    cancella: (body) => {
      return api.post("/ferie/richieste/cancella", body);
    },
    rifiuta: (body) => {
      return api.post("/ferie/richieste/rifiuta", body);
    },
    riapri: (body) => {
      return api.post("/ferie/richieste/riapri", body);
    },
    modifica: (body) => {
      return api.post("/ferie/richieste/modifica", body);
    },
    richiedirevoca: (body) => {
      return api.post("/ferie/richieste/richiedirevoca", body);
    },
    elencoAttive: (utente = undefined) => {
      return api.get(
        "/ferie/attive/elenco" + (utente !== undefined ? "/" + utente : "")
      );
    },
    presenze: (params) => {
      return api.get("/ferie/presenze?" + $.param(params));
    },
    previewRichieste: (tipo, utente = undefined) => {
      let endPoint = utente !== undefined ? "/" + utente : "";
      return api.get(`/ferie/richieste/preview${endPoint}?tipo=` + tipo);
    },
    multiday: (richiesta) => {
      return api.get(`/ferie/richieste/multiday/` + richiesta);
    },
    previewDashboardDipendente: () => {
      return api.get("/ferie/richieste/dashboard/dipendente");
    },
    previewDashboardAdmin: () => {
      return api.get("/ferie/richieste/dashboard/admin");
    },
    eliminaRichiesta: (body) => {
      return api.post("/ferie/richieste/elimina", body);
    },
  },
};
