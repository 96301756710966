/**
 * Styles.
 */
import "./style.scss";

/**
 * External Dependencies
 */
import { Component } from "react";

/**
 * Internal Dependencies
 */

/**
 * Component
 */
class GestioneFornitori extends Component {
  render() {
    window.location.href = "/dashboard/fornitori";
    return null;
  }
}

export default GestioneFornitori;
