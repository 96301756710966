/**
 * Styles.
 */
import './style.scss';

/**
 * External Dependencies
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

/**
 * Internal Dependencies
 */
import {
    updateSettings as actionUpdateSettings
} from '../../actions';
import AsyncComponent from '../../components/async-component';
import Icon from '../../components/icon';
import PageContent from '../../components/page-content';
import PageTitle from '../../components/page-title';
import PageWrap from '../../components/page-wrap';

/**
 * Component
 */
class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            admin: false
        }
    }

    loadUsername() {
        window.api.profilo.username()
            .then((res) => {
                this.setState({
                    username: (res.data.username).split(" ")[0]
                })
            })
            .catch((err) => {
                console.log(err);
            })
    }

    autorizzato(url) {
        window.api.risorse.autorizzato(url)
            .then((res) => {
                this.setState({
                    admin: res.data.autorizzato
                })
            })
            .catch((err) => {
                console.log(err);
            })
    }

    componentDidMount() {
        this.loadUsername();
        this.autorizzato("/admin-dashboard")
    }

    render() {

        const {
            updateSettings,
            settings
        } = this.props;

        const {
            username,
            admin
        } = this.state;

        return (
            <PageWrap>
                <PageTitle
                    breadcrumbs={{
                        '/': 'Home'
                    }}
                >
                    <br />
                    <Row>
                        <Col>
                            <h1>Ciao {username}!</h1>
                        </Col>
                        <Col>
                            {
                                admin ? (
                                    <div className="float-right">
                                        <Icon name="IconReplace" size="32" stroke="1" onClick={() => {
                                            updateSettings({
                                                admin_dashboard: !settings.admin_dashboard,
                                            })
                                        }} />
                                    </div>) : null
                            }
                        </Col>
                    </Row>
                </PageTitle>
                <PageContent>
                    <AsyncComponent component={() => import('./content')} />
                </PageContent>
            </PageWrap>
        );
    }
}

export default connect(({ settings }) => (
    {
        settings,
    }
), {
    updateSettings: actionUpdateSettings,
})(Dashboard);
