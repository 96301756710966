/**
 * External Dependencies
 */
import React, { Component } from "react";

/**
 * Internal Dependencies
 */
import AsyncComponent from "../../../components/async-component";
import PageContent from "../../../components/page-content";
import PageTitle from "../../../components/page-title";
import PageWrap from "../../../components/page-wrap";

/**
 * Component
 */
class ReportPlanningContrattiSettimanali extends Component {
  render() {
    return (
      <PageWrap>
        <PageTitle
          breadcrumbs={{
            "/": "Home",
            "#": {
              title: "Admin",
              sub: "admin",
            },
            "/estrazioni-planning": "Report planning",
          }}
        >
          <h1>Check vincoli settimanali</h1>
        </PageTitle>
        <PageContent>
          <AsyncComponent
            component={() => import("./content-check-settimana")}
          />
        </PageContent>
      </PageWrap>
    );
  }
}

export default ReportPlanningContrattiSettimanali;
