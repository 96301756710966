/**
 * Styles.
 */
import "./style.scss";

/**
 * External Dependencies
 */
import React, { Component } from "react";

/**
 * Internal Dependencies
 */
import AsyncComponent from "../../components/async-component";
import PageContent from "../../components/page-content";
import PageTitle from "../../components/page-title";
import PageWrap from "../../components/page-wrap";

/**
 * Component
 */
class GestioneUtentiBuoniPasto extends Component {
  render() {
    return (
      <PageWrap>
        <PageTitle
          breadcrumbs={{
            "/": "Home",
            "#": {
              title: "Admin",
              sub: "admin",
            },
            "/estrazioni-dipendenti": "Estrazioni dipendenti",
          }}
        >
          <h1>Buoni Pasto Dipendenti</h1>
        </PageTitle>
        <PageContent>
          <AsyncComponent component={() => import("./content-buoni-pasto")} />
        </PageContent>
      </PageWrap>
    );
  }
}

export default GestioneUtentiBuoniPasto;
