/**
 * Styles.
 */
import './style.scss';

/**
 * External Dependencies
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

/**
 * Internal Dependencies
 */
import {
    updateSettings as actionUpdateSettings
} from '../../actions';
import AsyncComponent from '../../components/async-component';
import PageContent from '../../components/page-content';
import PageTitle from '../../components/page-title';
import PageWrap from '../../components/page-wrap';

/**
 * Component
 */
class Clienti extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {

        const {
            updateSettings,
            settings
        } = this.props;

        return (
            <PageWrap>
                <PageTitle
                    breadcrumbs={{
                        '/': 'Home',
                        '/Clienti': {
                            title: 'Apps',
                            sub: 'apps',
                        },
                    }}
                >
                    <h1>Clienti</h1>
                </PageTitle>
                <PageContent>
                    <AsyncComponent component={() => import('./content')} />
                </PageContent>
            </PageWrap>
        );
    }
}

export default connect(({ settings }) => (
    {
        settings,
    }
), {
    updateSettings: actionUpdateSettings,
})(Clienti);
