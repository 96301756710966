/**
 * App Settings
 */
const settings = {
  night_mode: localStorage.getItem("night_mode") == "true" ? true : false,
  spotlight_mode:
    localStorage.getItem("spotlight_mode") == "true" ? true : false,
  show_section_lines:
    localStorage.getItem("show_section_lines") == "true" ? true : false,
  sidebar_dark: localStorage.getItem("sidebar_dark") == "true" ? true : false,
  sidebar_static:
    localStorage.getItem("sidebar_static") == "true" ? true : false,
  admin_dashboard: false,
  sidebar_small: false,
  sidebar_effect: "shrink",
  nav: true,
  nav_dark: localStorage.getItem("nav_dark") == "true" ? true : false,
  nav_logo_path: require("../../common-assets/images/logo_shell.svg"),
  nav_logo_white_path: require("../../common-assets/images/logo_shell_w.svg"),
  nav_logo_width: "120px",
  login_logo_width: "250px",
  nav_logo_url: "/",
  nav_align: "left",
  nav_expand: "lg",
  nav_sticky: true,
  nav_autohide: true,
  nav_container_fluid: true,
  home_url: "/",
  update_pic: false,
  navigation: {
    "#profilo": {
      name: "Profilo",
      icon: "IconUserCircle",
    },
    "#esci": {
      name: "Esci",
      icon: "IconLogout",
    },
  },
  navigation_sidebar: {
    "/": {
      label: "Apps",
      name: "Dashboard",
      icon: "IconDashboard",
    },

    // Apps
    "/planning": {
      name: "Planning",
      icon: "IconCalendar",
    },
    "/clienti": {
      name: "Clienti",
      icon: "IconBuildingCommunity",
    },
    "/dipendenti": {
      name: "Dipendenti",
      icon: "IconFriends",
    },
    "/auto": {
      name: "Auto",
      icon: "IconCar",
    },
    "/permessi": {
      name: "Richiesta Ore",
      icon: "IconPlaneDeparture",
    },
    "/nota-spese": {
      name: "Nota Spese",
      icon: "IconRoad",
      sub: {
        "/nota-spese": {
          name: "Calcola",
          icon: "IconFileSpreadsheet",
        },
        "/nota-spese/giustificativi": {
          name: "Giustificativi",
          icon: "IconLicense",
        },
      },
    },
    "/fatturazione": {
      name: "Esportazioni",
      icon: "IconDatabaseExport",
    },
    "/offerte": {
      name: "Offerte",
      icon: "IconWallpaper",
    },
    "/report": {
      name: "Report",
      icon: "IconDatabaseExport",
      sub: {
        "/estrazioni-planning": {
          name: "Planning",
          icon: "IconTimelineEventText",
        },
        "/estrazioni-dipendenti": {
          name: "Dipendenti",
          icon: "IconUsersGroup",
        },
      },
    },

    // Admin
    "/gestione-clienti": {
      label: "Admin",
      name: "Clienti",
      icon: "IconBuildingStore",
      sub: {
        "/gestione-clienti": {
          name: "Anagrafica",
        },
        "/gestione-offerte": {
          name: "Offerte",
        },
        "/gestione-commesse": {
          name: "Commesse",
        },
        "/gestione-sottocommesse": {
          name: "Sottocommesse",
        },
      },
    },
    "/gestione-fornitori": {
      name: "Acquisti",
      icon: "IconTruckLoading",
      sub: {
        "/gestione-fornitori": {
          name: "shell.next",
          icon: "IconExternalLink",
        },
        "/aws-costi-tenant": {
          name: "Servizi Si.Cloud",
          icon: "IconCloudDollar",
        },
      },
    },

    "/gestione-dipendenti": {
      name: "Dipendenti",
      icon: "IconUsers",
      sub: {
        "/gestione-dipendenti": {
          name: "Anagrafica",
        },
        "/richieste-smartworking": {
          name: "Richieste SmartWorking",
        },
        "/richieste-permessi": {
          name: "Richieste Ore",
        },
        "/richieste-extra": {
          name: "Straordinari",
        },
        "/gestione-planning": {
          name: "Planning",
        },
      },
    },
    "/gestione-auto": {
      name: "Veicoli",
      icon: "IconCarCrane",
      sub: {
        "/gestione-auto": {
          name: "Elenco",
        },
        "/richieste-auto": {
          name: "Richieste",
        },
        "/tabella-costi": {
          name: "Costi Benzina",
        },
      },
    },
    "/configurazioni": {
      name: "Shell Config",
      icon: "IconSettings",
      sub: {
        "/autorizzazioni": {
          name: "Autorizzazioni",
        },
      },
    },
  },
  urls: [
    "/profilo",
    "/",
    "/planning",
    "/clienti",
    "/dipendenti",
    "/auto",
    "/permessi",
    "/nota-spese",
    "/nota-spese/giustificativi",
    "/fatturazione",
    "/offerte",
    "/gestione-clienti",
    "/gestione-commesse",
    "/gestione-dipendenti",
    "/gestione-fornitori",
    "/aws-costi-tenant",
    "/gestione-offerte",
    "/gestione-sottocommesse",
    "/estrazioni-planning",
    "/estrazioni-planning/check-contratti-settimanali",
    "/estrazioni-dipendenti",
    "/estrazioni-dipendenti/cedolino",
    "/estrazioni-dipendenti/ferie-realtime",
    "/estrazioni-dipendenti/buoni-pasto",
    "/estrazioni-dipendenti/smartwork",
    "/estrazioni-dipendenti/permessi-straordinari",
    "/autorizzazioni",
    "/configurazioni",
    "/richieste-permessi",
    "/richieste-extra",
    "/richieste-smartworking",
    "/gestione-planning",
    "/gestione-auto",
    "/richieste-auto",
    "/tabella-costi",
  ],
  breadcrumbs_presets: {
    apps: {
      "/profilo": "Profilo",
      "/": "Dashboard",
      "/planning": "Planning",
      "/clienti": "Clienti",
      "/dipendenti": "Dipendenti",
      "/auto": "Auto",
      "/permessi": "Permessi",
      "/nota-spese": "Nota Spese",
      "/fatturazione": "Fatturazione",
      "/offerte": "Offerte",
    },
    admin: {
      "/gestione-clienti": "Gestione Clienti",
      "/gestione-fornitori": "Gestione Fornitori",
      "/gestione-offerte": "Gestione Offerte",
      "/gestione-commesse": "Gestione Commesse",
      "/gestione-sottocommesse": "Gestione Sottocommesse",
      "/gestione-dipendenti": "Gestione Dipendenti",
      "/autorizzazioni": "Autorizzazioni",
      "/richieste-smartworking": "Richieste Smartworking",
      "/richieste-permessi": "Richieste Ore",
      "/gestione-planning": "Gestione Planning",
      "/gestione-auto": "Gestione Auto",
      "/richieste-auto": "Richieste Auto",
      "/tabella-costi": "Tabella Costi",
    },
  },
  img_file: {
    empty: require("../../common-assets/images/icon-empty.svg"),
    zip: require("../../common-assets/images/icon-zip.svg"),
    rar: require("../../common-assets/images/icon-rar.svg"),
    html: require("../../common-assets/images/icon-html.svg"),
    php: require("../../common-assets/images/icon-php.svg"),
    css: require("../../common-assets/images/icon-css.svg"),
    js: require("../../common-assets/images/icon-js.svg"),
    doc: require("../../common-assets/images/icon-doc.svg"),
    txt: require("../../common-assets/images/icon-txt.svg"),
    pdf: require("../../common-assets/images/icon-pdf.svg"),
    xls: require("../../common-assets/images/icon-xls.svg"),
    png: require("../../common-assets/images/icon-png.svg"),
    jpg: require("../../common-assets/images/icon-jpg.svg"),
  },
};

/* Parse GET variables to change initial settings */
const $_GET = {};
window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (a, name, value) => {
  //NOSONAR
  $_GET[name] = value;
});

Object.keys($_GET).forEach((name) => {
  const isTrue = $_GET[name] === "1";

  switch (name) {
    case "setting-night-mode":
      settings.night_mode = isTrue;
      break;
    case "setting-show-section-lines":
      settings.show_section_lines = isTrue;
      break;
    case "setting-sidebar-small":
      settings.sidebar_small = isTrue;
      break;
    case "setting-sidebar-dark":
      settings.sidebar_dark = isTrue;
      break;
    case "setting-nav-dark":
      settings.nav_dark = isTrue;
      break;
    // no default
  }
});

export default settings;
