/**
 * External Dependencies
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';

/**
 * Internal Dependencies
 */
import { updateAuth as actionUpdateAuth } from './actions';
import ErrorBoundary from './ErrorBoundary';
import RoutesList from './pages';
import NotFoundPage from './pages/404';

/**
 * Component
 */
class Routes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        this.checkLoggedIn();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname != this.props.location.pathname) {
            this.checkLoggedIn();
        }
    }

    checkPermission() {
        const {
            history,
            settings
        } = this.props;

        let current_path = window.location.pathname;
        current_path = '/' + current_path.split('/')[1];

        for (let url of settings.urls) {
            if (url === current_path) {
                window.api.risorse.autorizzato(current_path)
                    .then((res) => {
                        this.setState({
                            loading: false
                        })
                        if (!res.data.autorizzato) {
                            history.push('/403');
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            }
        }
    }

    checkLoggedIn() {
        const {
            updateAuth
        } = this.props;

        this.setState({
            loading: true
        })

        if (['/403', '/sign-in'].indexOf(this.props.location.pathname) >= 0) {
            this.setState({
                loading: false
            })
            return;
        }

        window.api.auth.loggedIn()
            .then((res) => {
                if (!res.data.loggedIn) {
                    updateAuth({
                        token: '',
                    });
                } else {
                    updateAuth({
                        token: 'true',
                    });
                    this.checkPermission();
                }
            })
            .catch((err) => {
                console.log(err);
                updateAuth({
                    token: '',
                });
            });
    }

    render() {
        const {
            location,
        } = this.props;

        const {
            loading
        } = this.state;

        return (
            <ErrorBoundary>
                <Switch
                    location={location}
                >
                    {Object.keys(RoutesList).map((path) => {
                        const RouteInner = RoutesList[path];

                        return (
                            <Route
                                key={path}
                                path={path}
                                exact
                                render={() => !loading ? (
                                    <RouteInner />
                                ) : <></>}
                            />
                        );
                    })}

                    { /* 404 */}
                    <Route
                        render={() => (
                            <NotFoundPage />
                        )}
                    />
                </Switch>
            </ErrorBoundary>
        );
    }
}

export default connect(({ auth, settings }) => (
    {
        auth,
        settings
    }
), { updateAuth: actionUpdateAuth })(withRouter(Routes));
