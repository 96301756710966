/**
 * Styles
 */
import './style.scss';

/**
 * External Dependencies
 */
import classnames from 'classnames/dedupe';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';

/**
 * Internal Dependencies
 */
import { initPluginYaybar } from '../../../../common-assets/js/rootui-parts/initPluginYaybar';
import '../../../../common-assets/js/yaybar/yaybar';

import Icon from '../icon';

import { updateAuth as actionUpdateAuth } from '../../actions';

window.RootUI.initPluginYaybar = initPluginYaybar;

/**
 * Component
 */
class PageYaybar extends Component {
    constructor(props) {
        super(props);
        this.state = { permessi: undefined }
        this.renderSubmenus = this.renderSubmenus.bind(this);
        this.logOut = this.logOut.bind(this);
    }

    checkPolicy() {

        const {
            updateAuth,
        } = this.props;

        window.api.auth.getPolicy()
            .then((res) => {
                if (!res.data.policy) {
                    Swal.fire({
                        title: 'Hai letto il regolamento aziendale?',
                        html: 'Accettando questo popup confermi di aver preso visione del <a href="https://sinetinformaticait.sharepoint.com/sites/Intranet/SitePages/Regolamenti-e-Informazioni.aspx" target="_blank">regolamento aziendale</a>.<br/>Per utilizzare la piattaforma di gestione interna è obbligatorio accettare questa condizione.',
                        icon: 'warning',
                        input: 'checkbox',
                        inputPlaceholder: 'Ho letto il regolamento',
                        allowOutsideClick: false,
                    }).then((result) => {
                        if (result.value) {
                            window.api.auth.enablePolicy()
                                .then((resu) => {
                                    Swal.fire({ icon: 'success', text: 'Grazie mille ' + resu.data.nome + ', Bevenuto in Si.Net !' });
                                })
                                .catch(err => {
                                    console.log(err);
                                });
                        } else {
                            Swal.fire({ icon: 'error', text: "Devi accettare il regolamento" })
                                .then((resu) => {
                                    if (resu.value) {
                                        window.api.auth.logout()
                                            .then((resa) => {
                                                if (resa.status == 201) {
                                                    updateAuth({
                                                        token: '',
                                                    });
                                                }
                                            })
                                            .catch((err) => {
                                                console.log(err);
                                            })
                                    }
                                })
                                .catch(err => {
                                    console.log(err);
                                })
                        }
                    })

                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    componentDidMount() {
        window.RootUI.initPluginYaybar();
        this.checkPolicy();
        this.caricaPermessi();
    }

    logOut() {
        const {
            updateAuth,
        } = this.props;

        window.api.auth.logout()
            .then((res) => {
                if (res.status == 201) {
                    updateAuth({
                        token: '',
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    caricaPermessi() {
        window.api.auth.permessi()
            .then((res) => {
                this.setState({
                    permessi: res.data.permessi
                })
            })
            .catch((err) => {
                console.log(err);
            })
    }

    renderSubmenus(nav, returnObject = false) {

        const {
            permessi
        } = this.state;

        if (permessi != undefined) {
            let thereIsActive = false;

            const result = Object.keys(nav).map((url) => {

                if (permessi.filter(e => e.url === ("path::" + url)).length > 0) {
                    const data = nav[url];
                    const isActive = window.location.pathname === url;
                    let isOpened = false;

                    if (isActive) {
                        thereIsActive = true;
                    }

                    let sub = '';
                    if (data.sub) {
                        const subData = this.renderSubmenus(data.sub, true);

                        sub = (
                            <ul className="yay-submenu dropdown-triangle">
                                {subData.content}
                            </ul>
                        );

                        if (subData.thereIsActive) {
                            isOpened = true;
                            thereIsActive = true;
                        }
                    }

                    let navlin = "";

                    if (data.name) {
                        navlin = <NavLink
                            to={data.sub ? '#' : url}
                            className={data.sub ? 'yay-sub-toggle' : ''}
                        >
                            {data.icon ? (
                                <>
                                    <span className="yay-icon">
                                        <Icon name={data.icon} />
                                    </span>
                                    <span>{data.name}</span>
                                    <span className="rui-yaybar-circle" />
                                </>
                            ) : (
                                data.name
                            )}
                            {data.sub ? (
                                <span className="yay-icon-collapse">
                                    <Icon name="IconChevronRight" className="rui-icon-collapse" />
                                </span>
                            ) : ''}
                        </NavLink>
                    }

                    return (
                        <React.Fragment key={`${url}-${data.name}`}>
                            {data.label ? (
                                <li className="yay-label">{data.label}</li>
                            ) : ''}
                            <li className={classnames({
                                'yay-item-active': isActive,
                                'yay-submenu-open': isOpened,
                            })}>
                                {navlin}
                                {sub}
                            </li>
                        </React.Fragment>
                    );
                }
            });

            if (returnObject) {
                return {
                    content: result,
                    thereIsActive,
                };
            }

            return result;
        }
    }

    render() {
        const {
            settings,
        } = this.props;

        return (
            <>
                <div className={
                    classnames(
                        'yaybar rui-yaybar yay-hide-to-small yay-gestures',
                        settings.sidebar_dark && !settings.night_mode ? 'rui-yaybar-dark' : '',
                        settings.sidebar_static ? 'yay-static' : '',
                        settings.sidebar_effect ? `yay-${settings.sidebar_effect}` : '',
                    )
                }
                >
                    <div className="yay-wrap-menu">
                        <div className="yaybar-wrap">
                            <ul>

                                {this.renderSubmenus(settings.navigation_sidebar)}

                                <li className="yay-label">Account</li>
                                <li>
                                    <NavLink
                                        to="#"
                                        onClick={this.logOut}
                                    >
                                        <span className="yay-icon">
                                            <Icon name="IconLogout" />
                                        </span>
                                        <span>Esci</span>
                                        <span className="rui-yaybar-circle"></span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="rui-yaybar-bg" />
            </>
        );
    }
}

export default connect(({ settings }) => (
    {
        settings,
    }
), { updateAuth: actionUpdateAuth })(PageYaybar);
