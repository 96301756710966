/**
 * External Dependencies
 */
import * as TablerIcons from "@tabler/icons-react";
import PropTypes from "prop-types";
import React from "react";

/**
 * Component
 *
 * @param {Object} props component props.
 * @return {Object} component.
 */
const Icon = (props) => {
  let { name, size, button } = props;

  const opts = {
    ...props,
  };

  opts.className = `${opts.className || ""}`;

  if (opts.style == undefined) {
    opts.style = {};
  }

  if (!TablerIcons[name]) {
    name = "IconError404";
  }

  if (button == "big") {
    size = 32;
    opts.style.cursor = "pointer";
    opts.stroke = 1;
  }

  if (button == "std") {
    size = 28;
    opts.style.cursor = "pointer";
    opts.stroke = 1.5;
  }

  const RenderIcon = TablerIcons[name];

  if (!size) {
    size = 18;
  }

  return <RenderIcon {...opts} size={size} />;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  button: PropTypes.oneOf(["big", "std"]),
};

export default Icon;
